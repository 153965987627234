
import './index.scss';

import dfsgif from '../../assets/dfscolumns.gif';
import hopCodeGif from '../../assets/run_thru_cart.gif';

import hop1 from '../../assets/hopCode1.png';
import hop2 from '../../assets/hopCode2.png';
import hop3 from '../../assets/hopCode3.png';
import hop4 from '../../assets/hopCode4.png';
import hop5 from '../../assets/hopCode5.png';
import hop6 from '../../assets/hopCode6.png';

import dfsLogo from '../../assets/dfs.svg';
import Modal from '../Modal';
import {Link} from 'react-router-dom';
import React from 'react';

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';



function Projects() {
  const [ dfsShow, setDfsShow ] = React.useState(false);
  const [ dfsDetailShow, setDfsDetailShow ] = React.useState(false);
  const [ hopShow, setHopShow ] = React.useState(false);
  const [ hopDetailShow, setHopDetailShow ] = React.useState(false);
  
  function dfsImageClick(){
    setDfsShow(!dfsShow);
  }

  function hopImageClick(){
    setHopShow(!hopShow);
  }
  
 function revealDfs(){
  setDfsDetailShow(!dfsDetailShow);
 }

 function revealHop(){
  setHopDetailShow(!hopDetailShow);
 }

  return (
    <div className="page Projects">  
        <div className="case">
          <div className="title">
            <h1 >Capstone Project </h1> 
             <img  className="dfs-logo" src={dfsLogo} alt="logo for DreamsForSchools">
          </img>
          </div>
          <p>
        

            A web-based application that functions as an admin dashboard tailor-made for the organization  <span className="dfs"><a  href="https://www.dreamsforschools.org/">Dreams For Schools</a></span>.

            Dreams For Schools(DFS) is a nonprofit organization that partners with K-12 schools to teach students about science, technology, engineering, art, and math.

          </p>

          <div className="preview">
          <img className="dfs-gif" src={dfsgif} alt="animation showing UI work for DFS" onClick={dfsImageClick } ></img> 
          <Modal show={dfsShow} image={dfsgif} imageClick ={dfsImageClick}></Modal>
          
          </div>
          <div className="details">
          {!dfsDetailShow ? 
          <button className="btn" onClick={revealDfs}> SEE MORE</button> 
          :
          <button className="btn" onClick={revealDfs}> SEE LESS</button>}
          

          {dfsDetailShow && <div>
         <h1>What is the problem? </h1>
         <p> From what was once a small and manageable endeavor, spreadsheets were enough to handle seasonal assignments of their instructors to the many different schools that DFS serves.
          With their growth, the spreadsheets quickly grew into a large jumble of rows and columns. The application my team and I worked on, is the Instructor Assignment Sorter (IAS).
        </p>

        <h1> Tech Stack</h1>
        <p>
        Front-end is handled with the React framework. For our backend, data is saved using MySQL and the API runs using Express on Node.js. 
        The service itself also stores data using the Google Cloud platform and was deployed on Heroku.
        </p>

          <h1>The Solution</h1>
          <p>
            The IAS, is essentially an admin dashboard that helps an individual manage the organizations, groups, and individuals that DreamsForSchools worked with. It allows Partners, 
            Classes, and Instructors to all be added into a database which is presented and accessible to their scheduling team. Adding, moving, deleting, and other information is presented 
            to ease the process of scheduling for DFS.
          </p>
          </div>
         
}
        </div> 
        {/* end details */}
        </div>  
        

        <div className="case">
          <div className="title">
            <h1 >E-Commerce Project </h1> 
             {/* <img  className="dfs-logo" src={dfsLogo} alt="logo for DreamsForSchools"> */}
          {/* </img> */}
          </div>
          <p>
        

          In an attempt to practice different types of web development, HopSales is a fictional e-commerce site  using JSP and RESTful web services.
          The site was made with a combination of self-designed graphics, frog-stock photos, cart functionality, form submissions, and rating inputs. 

          </p>

          <div className="preview">
          <img className="hopCode-gif" src={hopCodeGif} alt="animation showing panes of ecommerce site" onClick={hopImageClick } ></img> 
          <Modal  show={hopShow} image={hopCodeGif} imageClick ={hopImageClick} custom="hopCode-modal"></Modal>
          
          </div>
          <div className="details">
          {!hopDetailShow ? 
          <button className="btn" onClick={ revealHop}> SEE MORE</button> 
          :
          <button className="btn" onClick={revealHop}> SEE LESS</button>}
          

          {hopDetailShow && <div>
         

        <h1> Tech Stack</h1>
        <p>
        Using java servlets, and AJAX, the site pushes to its own backend using MySQL. All of it hosted locally taking advantage of TomCat, and the database being on the local computer.
        </p>

          <h1>The Takeaway</h1>
          <p>
          All-in-all, it was interesting to use Java as a web-development language. As someone that started coding in Java, it felt full-circle since I hadn’t touched Java for a while 
          in favor of other web development frameworks. It was overall fun to take a different perspective from React and your traditional vanilla HTML/CSS/JS methods.

          </p>

          <div className="carousel">
          <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={50}
          totalSlides={5}
          >
            <Slider>
              <Slide index={0}> <img className="hopCode-prev" src={hop1} alt="hopCode example" ></img> </Slide>
              <Slide index={1}> <img className="hopCode-prev" src={hop2} alt="hopCode example" ></img> </Slide>
              <Slide index={2}> <img className="hopCode-prev" src={hop3} alt="hopCode example" ></img> </Slide>
              <Slide index={3}> <img className="hopCode-prev" src={hop4} alt="hopCode example" ></img> </Slide>
              <Slide index={4}> <img className="hopCode-prev" src={hop5} alt="hopCode example" ></img> </Slide>            
            </Slider>
          
          </CarouselProvider>        
          </div>
          <img className="hop-mobile" src={hop4} alt="hopCode example" ></img>
          </div>
         
}
        </div> 
        {/* end details */}
        </div>  




        <Link to="/contacts" className="btn">
          CONTACT ME
          {/* PUT AN ICON? */}
        </Link>
        


    </div>
  );
}

export default Projects;