import circle from '../../assets/casting_circle.svg';
import sparks from '../../assets/mobilebackground.png'
import './index.scss';
import { useRef } from 'react'


import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'


function Caster() {
  const container = useRef()

  return (
    <div className="App" ref={container}>      
        {/* <img src={circle} className="caster" alt="caster" />         */}
        <div className="fiber">
        <Canvas shadows dpr={[1, 2]} camera={{ position: [-2, 2, 6], fov: 50, near: 1, far: 20 }}>

        <Experience />
        </Canvas>
        </div>
        {/* <img src={sparks} className="sparks" alt="sparks" />         */}
        
    </div>
  );
}

export default Caster;