
import './index.scss';
import {Link ,  NavLink} from 'react-router-dom';


function SideNav() {
  return (
    <div className="nav">    
        {/* <Link className='item' to ='/'> */}


        <nav>

          {/* prob change the to's eventually , do i even want nav or do i want UL. hmmm*/}
          <NavLink className="item" exact="true" activeclassname="active" to="/">
          <p>ABOUT</p>
          </NavLink>

          <NavLink className="item" exact="true" activeclassname="active" to="/projects">
          <p>PROJECTS</p>
          </NavLink>

          <NavLink className="item" exact="true" activeclassname="active" to="/contacts">
          <p>CONTACT</p>
          </NavLink>
          
          {/* <span className="nav-slider"></span> */}
        </nav>
        
        {/* </Link> */}
      
    </div>
    
  );
}

export default SideNav;