
import './index.scss';


function Modal(props) {
    const {show, image, imageClick, custom} = props;

    return (
        <div >
        {show ? 
        <div className={"modal "+ custom} > 
            <img className="dfs-gif" src={image} alt="animation" onClick={imageClick } ></img> 

        </div>
        :
        
            null
        
        }
        </div>
    );
  }
  
  export default Modal;