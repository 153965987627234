
import { useRef } from 'react'
import { useThree, extend, useFrame } from '@react-three/fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { useGLTF, SpotLight, useDepthBuffer, Sparkles } from '@react-three/drei'
import { Vector3 } from 'three'


extend({ OrbitControls })




function MovingSpot({ vec = new Vector3(), ...props }) {
    const light = useRef()
    const viewport = useThree((state) => state.viewport)
    useFrame((state) => {
      light.current.target.position.lerp(vec.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 0), 0.1)
      light.current.target.updateMatrixWorld()
    })
    return <SpotLight castShadow ref={light} penumbra={2} distance={10} angle={0.35} attenuation={5} anglePower={4} intensity={2} {...props} />
  }

export default function Experience()
{

    const cubeRef = useRef()
    const dosRef = useRef()
    const groupRef = useRef()
    
    const { camera, gl } = useThree()
    // var lightPosition =[8, 3, 3]
    
    
    const { nodes, materials } = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/dragon/model.gltf')
    
    useFrame((state) =>
    {
    // cubeRef.current.rotation.y += delta
    // groupRef.current.rotation.y += delta
        // const x = (state.mouse.x * viewport.width) / 2
        // const y = (state.mouse.y * viewport.height) / 2
        // console.log(x)
        // console.log(y)
       

        const time = state.clock.getElapsedTime();
        
        cubeRef.current.position.y = Math.cos( time ) * 0.2;
        dosRef.current.position.y = Math.sin( time ) * 0.33;
    })

    


    return <>
            <orbitControls args={ [ camera, gl.domElement ] } />
            {/* <directionalLight /> */}
            <ambientLight intensity={ 0.02 } />
            {/* <pointLight position={lightPosition}  />  */}
            <MovingSpot  color="#05a175" position={[1, 3, 2]} />
            <MovingSpot color="#003efa" position={[-2, 3, 2]} />
    <group ref={ groupRef }>   
    

    
    <mesh ref={ cubeRef } rotation-y={ Math.PI * 0.25 } position-x={ 1 } position-z={1} scale={ 1.5 }>
        <boxGeometry />
        <meshPhongMaterial  color="#29f2ec" />        
        
    </mesh>
    <Sparkles count={50} size={1.5} position={[1, 0, 1]} scale={[2, 2, 2]} speed={0.2} />    
    
    <mesh ref={ dosRef } rotation-y={ Math.PI * 0.6 } position-x={ -2 }  scale={ 1 }>
        <boxGeometry />
        <meshPhongMaterial  color="#29f2ec" />
    </mesh>
    <Sparkles count={50} size={1.5} position={[-2, 0, 0]} scale={[2, 2, 2]} speed={0.2} />
</group>


    </>
}