
import './App.scss';
import Caster from './components/caster';
import Intro from './components/Intro';
import About from './components/About';
import Projects from './components/Projects';
import Contacts from './components/Contacts';
import { Routes , Route} from 'react-router-dom';

function App() {
  return (
    
    
    <Routes>
      <Route path="/" element={<Intro/>}>   
      <Route index element={<About/>}></Route>
      <Route path="projects" element={<Projects/>}></Route>
      <Route path="contacts" element={<Contacts/>}></Route>
      <Route path="*" element={<About />} />
      </Route>
      
    </Routes>
    
    
  );
}

export default App;
