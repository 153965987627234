
import './index.scss';

import {Link} from 'react-router-dom';
import Status from '../Status';
import user from '../../assets/mikey_png.png';

function About() {
  return (
    <div>
    <Status/>
    <div className="page About">  
    
        <div className="text-intro">
          <h1>Hi, <br/> I'm </h1>
          <p className="name"> Michael Wijangco</p>

        {/* maybe animate JUST musician to its own component? cycles
        between words? */}
          <h2> Developer / Designer  </h2>

          
          <div className="card">
            <img src={user} className="user" alt="Mikey" />            
            
            <div className="card-in">
              <div className="inners">
            <h2> ABOUT </h2>
                <p>  I'm a fan of the process that intertwines design and implementation. 
                </p>
                  <p>Current hyperfixation : <em>Three.js</em></p>
        
              </div>          
            </div>
          </div>  
          </div>
         

        <Link to="/contacts" className="btn">
          CONTACT ME
          {/* PUT AN ICON? */}
        </Link>
        


    </div>
    </div>
  );
}

export default About;