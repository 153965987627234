
import './index.scss';
import React, { useRef } from 'react';
import * as emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


import {
  faLinkedin,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {Link} from 'react-router-dom';



function Contacts() {


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hosdndd', 'template_v0jm23u', form.current, '6cHCfYfTLQDH1HS3m')
      .then((result) => {
          console.log(result.text);
          toast.dark('Thanks! Email sent, I will get back to you ASAP!', {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      }, (error) => {
          console.log(error.text);
          toast.dark('Something went wrong with the email, try one more time or contact me @ mnwijangco@gmail.com', {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      });
  };

  


  return (
    <div className="page Contact">  
        <div className="text-intro">
          <h1> CONTACT </h1>
          

          <a href="https://github.com/mwijangc"> 
          
          <FontAwesomeIcon icon={faGithub} color="var(--off-white)"/>

          </a>

          <br></br>
          <a href="https://www.linkedin.com/in/michael-wijangco-1226b7196">
          
          <FontAwesomeIcon icon={faLinkedin} color="var(--off-white)"/>

          </a>

          <form ref={form} onSubmit={sendEmail}>
            <div className="box">
            <label>Name</label>
            <br></br>
            <input type="text" name="user_name" />
            </div>
            <div className="box">
            <label>Email</label>
            <br></br>
            <input type="email" name="user_email" />
            </div>
            <div className="box">
              <label>Message</label>
            <br></br>
            <textarea name="message" />
            </div>
            <input className="btn" type="submit" value="Send" />
          </form>

          </div>  
          <ToastContainer
          position="top-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />      
       
        


    </div>
  );
}

export default Contacts;