
import './index.scss';
import user from '../../assets/mk_ui.svg';
import {
  faLinkedin,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Status() {
  return (
    <div className="status-window animate__animated animate__backInRight">
      <span className="player">
        <p>Mikey</p>
      </span>
      <span className="portrait">
        <img src={user}></img>
      </span>
      <div className="ui">   
        <div className="class-title">
            <p> D E V E L O P E R </p>
         </div> 
          
          <div className="links">
          <a href="https://github.com/mwijangc"> 
          
          <FontAwesomeIcon icon={faGithub} color="var(--off-white)"/>

          </a>

          
          <a href="https://www.linkedin.com/in/michael-wijangco-1226b7196">
          
          <FontAwesomeIcon icon={faLinkedin} color="var(--off-white)"/>

          </a>
          </div>
          

      </div>
      
    </div>
  );
}

export default Status;