
import './index.scss';
import SideNav from '../SideNav';
import {Outlet} from 'react-router-dom';
import Caster from '../caster';
// import Status from '../Status';

function Intro() {
  return (
    <div className='wrapper'>    
        <SideNav/>
        {/* <Status/> */}
        <div className='sect'>
        <Caster/>

        <Outlet/>

        </div>
    </div>
  );
}

export default Intro;